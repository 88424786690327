import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./index.scss";

const Home = lazy(() => import("./screens/Home"));
const Question = lazy(() => import("./screens/Question"));
const Form = lazy(() => import("./screens/Form"));
const Result = lazy(() => import("./screens/Result"));
const NotFound = lazy(() => import("./screens/NotFound"));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Switch>
          <Route path="/" component={() => <Home />} exact />
          <Route path="/vraag" exact>
            <Redirect to="/vraag/1" />
          </Route>
          <Route path="/vraag/:id" component={() => <Question />} exact />
          <Route path="/gegevens" component={() => <Form />} exact />
          <Route path="/resultaat" exact>
            <Redirect to="/" />
          </Route>
          <Route path="/resultaat/:id" component={() => <Result />} exact />
          <Route component={() => <NotFound />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
